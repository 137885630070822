import * as React from "react"

import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage} from "gatsby-plugin-image"
import { Helmet } from "react-helmet"
import BackgroundImage from 'gatsby-background-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay  } from 'swiper/core'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Fade, Slide } from "react-awesome-reveal"
import favicon from '../images/favicon.png'
import { OutboundLink } from "gatsby-plugin-google-gtag"

import Card from '../components/card'

import 'swiper/swiper.scss'

SwiperCore.use([Autoplay])

const IndexPage = () => {
	const [isOpen, setisOpen] = React.useState(false);
	function handleClick() {
		setisOpen(!isOpen);
	}

	const data = useStaticQuery(
		graphql`
		  query {
			desktop: file(relativePath: { eq: "hero-cover.jpg" }) {
			  childImageSharp {
				fluid(quality: 90, maxWidth: 1920) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}
		  }
		`
	  )
	
	const imageData = data.desktop.childImageSharp.fluid

	function handleClickWhatsapp() {
		window.gtag("event", "conversion", { send_to: ["AW-353107821/7nKUCJis2b8CEO3-r6gB"]})
	}
	function handleClickCall() {
		window.gtag("event", "conversion", { send_to: ["AW-353107821/mMYRCJjuhMACEO3-r6gB"]})
	}
	

	return (
		<>
			<Helmet>
				<title>Akilaku.id - Siap Antar dan Pasang Aki Mobil ke Lokasi Anda</title>
				<link rel="icon" type="image/png" href={favicon} sizes="16x16" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
			</Helmet>
			
			<div id='home' className="flex flex-col w-full h-auto">
				<div className="navbar sticky top-0 bg-gradient-to-r from-yellow-500 via-yellow-400 to-yellow-500 w-full shadow-lg z-40 border-b border-yellow-500">
					<div className="flex flex-col py-4 px-8 md:px-14">
						<div className="flex flex-row justify-between">
							<div className="logo m-1 flex items-center justify-center z-30">
								<StaticImage src="../images/logo.png" alt="logo" loading='{ eager }' />
							</div>
							<div className="hidden lg:flex lg:flex-row">
								<AnchorLink to='/#home' className="flex flex-row gap-x-1 nav-item p-2 m-1 items-center justify-center text-lg">
									<div className='flex-wrap content-center'>
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
											<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
										</svg>
									</div>
									<span>Home</span>
								</AnchorLink>
								<AnchorLink to='/#produk' className="flex flex-row gap-x-1 nav-item p-2 m-1 items-center justify-center text-lg">
									<div className='flex-wrap content-center'>
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
											<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
										</svg>
									</div>
									<span>Produk</span>
								</AnchorLink>
								<AnchorLink to='/#testimonial' className="flex flex-row gap-x-1 nav-item p-2 m-1 items-center justify-center text-lg">
									<div className='flex-wrap content-center'>
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
											<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
										</svg>
									</div>
									<span>Testimonial</span>
								</AnchorLink>
								<AnchorLink to='/#kontak' className="flex flex-row gap-x-1 nav-item p-2 m-1 items-center justify-center text-lg">
									<div className='flex-wrap content-center'>
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
											<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
										</svg>
									</div>
									<span>Kontak</span>
								</AnchorLink>
							</div>
							<button type="button" className="block lg:hidden px-4" onClick={handleClick}>
								<svg className="h-6 w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
									{isOpen && (
									<path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
									)}
									{!isOpen && (
									<path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
									)}
								</svg>
							</button>
						</div>
						<div className={`block lg:hidden ${  isOpen ? "block" : "hidden" }`}>
							<AnchorLink to='/#home' className="flex flex-row gap-x-2 nav-item p-4 mt-4 justify-center border border-gray-700">
								<div className='flex-wrap content-center'>
									<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
									</svg>
								</div>
								<span>Home</span>
							</AnchorLink>
							<AnchorLink to='/#produk' className="flex flex-row gap-x-2 nav-item p-4 mt-2 justify-center border border-gray-700">
								<div className='flex-wrap content-center'>
									<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
									</svg>
								</div>
								<span>Produk</span>
							</AnchorLink>
							<AnchorLink to='/#testimonial' className="flex flex-row gap-x-2 nav-item p-4 mt-2 justify-center border border-gray-700">
								<div className='flex-wrap content-center'>
									<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
									</svg>
								</div>
								<span>Testimonial</span>
							</AnchorLink>
							<AnchorLink to='/#kontak' className="flex flex-row gap-x-2 nav-item p-4 mt-2 justify-center border border-gray-700">
								<div className='flex-wrap content-center'>
									<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
									</svg>
								</div>
								<span>Kontak</span>
							</AnchorLink>
						</div>
					</div>
				</div>
					
				<BackgroundImage
					Tag="section"
					className='hero'
					fluid={imageData}
					backgroundColor={`#000`}
					>
					<div className='heading-container absolute flex flex-col justify-center items-center w-full h-full'>
						<h2 className='text-3xl md:text-4xl text-white text-center leading-7 md:leading-10'><strong>Mau beli aki mobil tanpa ribet?</strong></h2>
						<p className='text-xl md:text-xl text-white text-center leading-7 md:leading-10'>Kami siap antar dan pasang aki mobil ke lokasi anda</p>
						<div className='flex flex-col text-center lg:flex-row'>
							<div className='whatsapp-button m-2'>
								<OutboundLink onClick={handleClickWhatsapp} href="https://api.whatsapp.com/send?phone=628111983588&text=Halo%2C akilaku.id" target="_blank" rel="noreferrer" className="transition-color duration-1000 ease-in-out motion-reduce:transition-none motion-reduce:transform-none bg-green-500 hover:bg-green-400 text-gray-800 font-bold py-3 px-4 rounded inline-flex items-center shadow-md">
									<FontAwesomeIcon className='w-5 h-5 mr-2' icon={faWhatsapp} />
									<span>Whatsapp Kami</span>
								</OutboundLink>
							</div>
							<div className='phone-button m-2'>
								<OutboundLink onClick={handleClickCall} href='tel:+628111983588' className="w-full transition-color duration-1000 ease-in-out motion-reduce:transition-none motion-reduce:transform-none bg-yellow-500 hover:bg-yellow-400 text-gray-800 font-bold py-3 px-4 rounded inline-flex items-center shadow-md">
								<FontAwesomeIcon className='w-4 h-4 mr-2' icon={faPhone} rotation={90}/>
									<span>Telepon Kami</span>
								</OutboundLink>
							</div>
						</div>
					</div>
				</BackgroundImage>

				<section id='tentang' className='container mx-auto text-center py-8 px-4 mx-4 mt-4 mb-8'>
					<Fade triggerOnce='true'>
						<div className="header text-3xl mx-2 mt-2 mb-6">
							<h2 className='my-2'><strong>Tentang Kami</strong></h2>
						</div>
						<p className='text-lg'>
							<strong>AkiLaku.id</strong> merupakan toko aki mobil online atau toko aki mobil terdekat dari daerah anda yang menyediakan produk-produk aki mobil terlengkap berkualitas & bergaransi. <br/>
							<strong>AkiLaku.id</strong> menyediakan aki mobil GS Astra, Incoe, Yuasa, Solite, Amaron, Faster MF, Faster JP, Furukawa Battery, NGS, Varta, Panasonic, Bosch, Delkor, Massiv, Motobatt dll. <br />
							<strong>AkiLaku.id</strong> hanya menjual aki mobil baru dan original, sehingga kami salah satu toko aki mobil online terpercaya bagi anda yang membutuhkan aki mobil yang terjamin kualitasnya.
						</p>
					</Fade>
				</section>

				<section id='keunggulan' className='bg-gradient-to-r from-yellow-500 via-yellow-400 to-yellow-500 mx-auto text-center w-full px-8 py-6 md:py-8 md:px-8'>
					<div className='container mx-auto'>
						<div className="header text-3xl m-2 mb-6">
							<h2><strong>Keunggulan Kami</strong></h2>
						</div>
						<div className='grid grid-cols-1 xl:grid-cols-5 justify-center items-center'>
							<Slide delay='30' triggerOnce='true' direction={'up'}>
								<Card>
									<StaticImage src="../images/original.png" alt="delivery" width={128} height={128} loading='{ lazy }' />
									<h3 className='text-xl'><strong>Aki Baru & Original</strong></h3>
									<p className='mt-2 mb-4'><strong>Akilaku.id</strong> hanya menjual aki mobil baru, dan original. Kami berkomitmen tidak menjual aki mobil tiruan atau rekondisi.</p>
								</Card>
							</Slide>
							<Slide delay='30' triggerOnce='true' direction='up'>
								<Card>
									<StaticImage src="../images/gurantee.png" alt="accumulator" width={128} height={128} loading='{ lazy }' />
									<h3 className='text-xl'><strong>Aki Mobil Bergaransi</strong></h3>
									<p className='mt-2 mb-4'><strong>Akilaku.id</strong> selalu memberikan garansi bagi anda yang memesan aki mobil online melalui kami</p>
								</Card>
							</Slide>
							<Slide delay='30' triggerOnce='true' direction='up'>
								<Card>
									<StaticImage src="../images/delivery.png" alt="delivery" width={128} height={128} loading='{ lazy }' />
									<h3 className='text-xl'><strong>Gratis Antar & Pasang</strong></h3>
									<p className='mt-2 mb-4'>Selain menjual aki mobil baru, kami juga memberikan layanan jasa gratis antar dan pasang ketika anda membeli aki mobil melalui <strong>Akilaku.id</strong>.</p>
								</Card>
							</Slide>
							<Slide delay='30' triggerOnce='true' direction='up'>
								<Card>
									<StaticImage src="../images/tradein.png" alt="delivery" width={128} height={128} loading='{ lazy }' />
									<h3 className='text-xl'><strong>Tukar Tambah Aki Lama</strong></h3>
									<p className='mt-2 mb-4'>Anda dapat menukarkan aki mobil bekas anda, sehingga mengurangi harga aki mobil baru yang anda beli</p>
								</Card>
							</Slide>
							<Slide delay='30' triggerOnce='true' direction='up'>
								<Card>
									<StaticImage src="../images/aki.png" alt="accumulator" width={128} height={128} loading='{ lazy }' />
									<h3 className='text-xl'><strong>Jenis Aki Lengkap</strong></h3>
									<p className='mt-2 mb-4'>Kami menjual beragam jenis aki mobil seperti Accu Conventional, Accu Hybrid, Accu Maintenance Free dengan beragam merek.</p>
								</Card>
							</Slide>
						</div>
					</div>
				</section>

				<section id='testimonial' className='container mx-auto text-center'>
					<div className='testimonial-content px-8 py-6 md:py-8 md:px-8'>
						<div className="header text-3xl m-2 mb-6 md:mb-8">
							<h2><strong>Testimonials</strong></h2>
						</div>
						<Swiper
						slidesPerView={1}
						centeredSlides={true} 
						autoplay={{
							"delay": 4000,
							"disableOnInteraction": false
						}} 
						>
							<SwiperSlide>
								<div className='flex flex-col w-2/3 lg:w-3/6'>
									<StaticImage layout={'fixed'} height={128} width={128} src="../images/dimas.png" alt="dimas" className='w-32 m-auto' loading='{ eager }'/>
									<h4 className='text-xl mt-2'><strong>Dimas</strong></h4>
									<h5 className='text-sm my-1'>Driver Online</h5>
									<p><strong>Akilaku.id</strong> sangat recommended bagi kami yang mobilitasnya sangat tinggi, bisa dapat aki mobil dengan harga terjangkau dan dapat promo tukar tambah</p>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className='flex flex-col w-2/3 lg:w-3/6'>
									<StaticImage layout={'fixed'} height={128} width={128} src="../images/lia2.png" alt="lia" className='w-32 m-auto' loading='{ eager }'/>
									<h4 className='text-xl mt-2'><strong>Lia</strong></h4>
									<h5 className='text-sm my-1'>Staff Swasta</h5>
									<p><strong>Akilaku.id</strong> sangat membantu ketika mobil saya mogok, mekanik langsung datang dengan cepat. Cocok bagi pengemudi wanita yang kebingungan dijalan</p>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className='flex flex-col w-2/3 lg:w-3/6'>
									<StaticImage layout={'fixed'} height={128} width={128} src="../images/riki.png" alt="riki" className='w-32 m-auto' loading='{ eager }'/>
									<h4 className='text-xl mt-2'><strong>Riki</strong></h4>
									<h5 className='text-sm my-1'>Wiraswasta</h5>
									<p><strong>Akilaku.id</strong> memang solusi ketika saya membutuhkan aki mobil cepat, mekanik langsung antar dan pasangkan aki mobil ke rumah. Terimakasih <strong>Akilaku.id</strong></p>
								</div>
							</SwiperSlide>
						</Swiper>
					</div>
				</section>

				<section id='produk' className='mx-auto text-center bg-gray-100 w-full px-8 py-6 md:py-8 md:px-8'>
					<div className="header text-3xl m-2 mb-6 md:mb-8">
						<h2><strong>Produk</strong></h2>
					</div>
					<div className='container mx-auto grid grid-cols-1 xl:grid-cols-3 justify-center items-center'>
						<Fade delay='30' triggerOnce='true' direction={'bottom'}>
							<div className='produk-item'>
								<div className='bg-white border border-gray-300 shadow-md p-4 pb-8 m-3'>
									<StaticImage src="../images/aki-basah.png" alt="aki-basah" width={400} height={250} loading='{ lazy }' />
									<h3 className='text-xl'><strong>Conventional Battery<br/>(Aki Mobil Basah)</strong></h3>
								</div>
							</div>
						</Fade>
						<Fade delay='30' triggerOnce='true' direction={'bottom'}>
							<div className='produk-item'>
								<div className='bg-white border border-gray-300 shadow-md p-4 pb-8 m-3'>
									<StaticImage src="../images/aki-hybrid.png" alt="aki-basah" width={400} height={250} loading='{ lazy }' />
									<h3 className='text-xl'><strong>Hybrid Battery<br/>(Aki Mobil Hybrid)</strong></h3>
								</div>
							</div>
						</Fade>
						<Fade delay='30' triggerOnce='true' direction={'bottom'}>
							<div className='produk-item'>
								<div className='bg-white border border-gray-300 shadow-md p-4 pb-8 m-3'>
									<StaticImage src="../images/aki-mf.png" alt="aki-basah" width={400} height={250} loading='{ lazy }' />
									<h3 className='text-xl'><strong>Maintenance Free Battery<br/>(Aki Mobil Kering)</strong></h3>
								</div>
							</div>
						</Fade>
					</div>
				</section>

				<section id='brand' className='mx-auto text-center bg-gray-900 w-full px-8 py-6 md:py-8 md:px-8'>
					<div className='container mx-auto m-2'>
						<Swiper
							slidesPerView={2}
							spaceBetween={50}
							autoplay={{
								"delay": 2000,
								"disableOnInteraction": false
							}}
							breakpoints={{
								1024: {
								  slidesPerView: 4,
								},
							}}
							>
								<SwiperSlide>
									<StaticImage src="../images/gsastra.png" alt="gsastra" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/amaron.png" alt="amaron" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/bosch.png" alt="bosch" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/delkor.png" alt="delkor" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/faster.png" alt="faster-jp" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/fasterr.png" alt="faster" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/furukawa.png" alt="furukawa" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/incoe.png" alt="incoe" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/massiv.png" alt="massiv" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/mottobat.png" alt="mottobat" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/ngs.png" alt="ngs" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/panasonic.png" alt="panasonic" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/solite.png" alt="solite" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/varta.png" alt="varta" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/yuasa.png" alt="yuasa" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
						</Swiper>
					</div>
				</section>

				<section id='kontak' className='container mx-auto text-center px-8 py-6 md:py-8 md:px-8'>
					<div className="header text-3xl m-2 mb-6 md:mb-8">
						<h2><strong>Mau Info Lebih Lanjut?</strong></h2>
					</div>
					<div className='flex flex-col'>
						<div className='flex justify-center mb-3'>
							<div className='flex flex-col text-center lg:flex-row'>
								<div className='whatsapp-button m-2'>
								<OutboundLink onClick={handleClickWhatsapp} href="https://api.whatsapp.com/send?phone=628111983588&text=Halo%2C akilaku.id" target="_blank" rel="noreferrer" className="transition-color duration-1000 ease-in-out motion-reduce:transition-none motion-reduce:transform-none bg-green-500 hover:bg-green-400 text-gray-800 font-bold py-3 px-4 rounded inline-flex items-center shadow-md">
									<FontAwesomeIcon className='w-5 h-5 mr-2' icon={faWhatsapp} />
									<span>Whatsapp Kami</span>
								</OutboundLink>
								</div>
								<div className='phone-button m-2'>
									<OutboundLink onClick={handleClickCall} href='tel:+628111983588' className="w-full transition-color duration-1000 ease-in-out motion-reduce:transition-none motion-reduce:transform-none bg-yellow-500 hover:bg-yellow-400 text-gray-800 font-bold py-3 px-4 rounded inline-flex items-center shadow-md">
									<FontAwesomeIcon className='w-4 h-4 mr-2' icon={faPhone} rotation={90}/>
										<span>Telepon Kami</span>
									</OutboundLink>
								</div>
							</div>
						</div>
						<div className='no-text m-2'>
							<strong>+628111983588</strong>
						</div>
					</div>
				</section>

				<div className='footer text-center text-sm bg-gray-900'>
					<p className='text-white py-3'>&copy; 2021 <a href="https://www.akilaku.id">Akilaku.id</a> All Right Reserved<br/></p>
				</div>
			</div>
			<OutboundLink onClick={handleClickWhatsapp} href="https://api.whatsapp.com/send?phone=628111983588&text=Halo%2C akilaku.id" className="wa-float" target="_blank" rel="noreferrer">
				<FontAwesomeIcon className='wa-my-float' icon={faWhatsapp} />
			</OutboundLink>
			<OutboundLink onClick={handleClickCall} href="tel:+628111983588" className="bg-yellow-500 call-float" rel="noreferrer">
				<FontAwesomeIcon className='call-my-float' icon={faPhone} />
			</OutboundLink>
		</>
	)
}

export default IndexPage
