import React from 'react'

const Card = ({children}) => {
    return (
        <>
            <div className='bg-white border border-gray-300 shadow-md p-4 m-3'>
                {children}
            </div>
        </>
    )
}

export default Card